<template>
  <div>
    <InputField label="Password" :onChange="doChange" type="password" />
    <Validator :text="value" :validate="validate" :noLabel='true'/>
  </div>
</template>
<script>
import Validator from "./Validator";
import InputField from "../InputField/InputField";
export default {
  name: "PasswordForm",
  components: { Validator, InputField },
  data() {
    return { value: "", isValid: false };
  },
  props: {
    setValidity: { type: Function, default: null },
    onChange: {
      type: Function,
      default: function(value) {
        this.value = value;
      },
    },
  },
  methods: {
    doChange(val) {
      this.onChange(val);
      this.value = val;
    },
    validate(value) {
      this.isValid = value;
      this.setValidity(value);
    },
  },
};
</script>
