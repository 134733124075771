<template>
  <div>
    <p v-if="noLabel" class="my-1 text-md text-grey-dark-1 mb-2">
      Your password needs to contain:
    </p>
    <div v-for="(item, idx) in validation" :key="idx">
      <div class="flex items-center pl-3 mb-1.5">
        <div
          class="w-1.5 h-1.5 rounded-full mr-1"
          :class="item.fulfilled ? 'bg-green-2' : 'bg-grey-light-1'"
        />
        <p class="text-md text-grey-dark-1">
          {{ item.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

const minLength = (len) => (e) => e.length >= len;
const hasLowerCase = (e) => /[a-z]/.test(e);
const hasUpperCase = (e) => /[A-Z]/.test(e);
const hasNumerical = (e) => /[0-9]/.test(e);
const hasSpecial = (e) => /[!@#$%^&*]/.test(e);

export default {
  name: "Validator",
  props: {
    text: { type: String, default: null },
    validate: { type: Function, default: null },
    noLabel: { type: Boolean, default: false },
  },
  validations: {
    text: {
      minLength: minLength(8),
      hasLowerCase,
      hasUpperCase,
      hasNumerical,
      hasSpecial,
    },
  },
  computed: {
    validation() {
      return [
        {
          text: "at least 8 characters",
          fulfilled: this.$v.text.minLength,
        },
        {
          text: "at least one lower case letter",
          fulfilled: this.$v.text.hasLowerCase,
        },
        {
          text: "at least one upper case letter",
          fulfilled: this.$v.text.hasUpperCase,
        },
        {
          text: "at least one number",
          fulfilled: this.$v.text.hasNumerical,
        },
        {
          text: "at least one special character",
          fulfilled: this.$v.text.hasSpecial,
        },
      ];
    },
  },
  watch: {
    validation(newValue) {
      if (this.validate) {
        const found = newValue.find((e) => e.fulfilled == false);
        this.validate(found ? false : true);
      }
    },
  },
};
</script>
