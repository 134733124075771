<template>
  <div class="w-full mt-7">
    <div class="w-3/4 maxWidth mx-auto">
      <img src="@/assets/img/logo.svg" width="130" class="mb-3" />
    </div>
    <div class="flex w-3/4 maxWidth mx-auto">
      <div class="bg-white p-6 pb-7 flex-grow">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-6">
          Reset Password
        </p>
        <p class="text-mdh text-grey-dark-1 mb-2">
          Please create a new password to access your account.
        </p>
        <PasswordForm :setValidity="setValidity" :onChange="changePass" />
        <div class="flex flex-col items-center mt-6 mb-4">
          <Button
            text="Save"
            type="primary"
            size="large"
            class="w-full"
            :onClick="onClickSave"
            :disabled="!validPassword || !goToSignInDisabled"
          />
        </div>
        <Button
          text="Go to Sign in"
          type="tertiary"
          size="medium"
          :onClick="onClickGo"
          class="mx-auto"
          :disabled="goToSignInDisabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PasswordForm from "@/components/PasswordValidation/PasswordForm.vue";
import { mapActions } from "vuex";

export default {
  name: "ResetPassword",
  data: () => ({
    validPassword: false,
    goToSignInDisabled: true,
    code: null,
    email: null,
    password: null,
  }),
  components: { PasswordForm },
  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword",
    }),
    setValidity(value) {
      this.validPassword = value;
    },
    onClickGo() {
      this.$router.push("/login");
    },
    async onClickSave() {
      var resetPasswordResult = await this.resetPassword({
        email: this.email,
        token: this.code,
        password: this.password,
        skipLogin: true,
      });
      this.goToSignInDisabled = !resetPasswordResult;
    },
    changePass(val) {
      this.password = val;
    },
  },
  mounted() {
    this.code = this.$route.query.code;
    this.email = this.$route.query.userEmail;
  },
  beforeRouteEnter(to, _, next) {
    if (to.query.code && to.query.userEmail) {
      next();
    } else {
      next("/login");
    }
  },
};
</script>
<style lang="scss" scoped>
.maxWidth {
  max-width: 678px;
}
</style>
